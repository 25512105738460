import { createTheme } from '@mui/material/styles';

const gsTheme = createTheme({
  palette: {
    primary: {
      main: '#122649',
    },
    secondary: {
      main: '#F0B41C',
    },
    // mode: 'dark',
  },
  typography: {
    fontFamily: 'Source Sans Pro,sans-serif',
  },
});

export { gsTheme };
