/** @mui/material imports */
import Box from '@mui/material/Box';
/** @mui/icons-material imports */
/** React imports */
import React, { Context, createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
/** Goldspot imports */
import { ProjectObject } from './TypeScript/interfaces';
import { getProject } from './utils/authenticated-requests/projects';
import { errorHandler } from './utils/error/error-handler';
import AppScopeLoadingComponent from './utils/components/AppScopeLoading';
import { Toaster } from 'react-hot-toast';

const AppRouter = React.lazy(() => {
  return import('./Router/router');
});

interface AppProps {
  user: {
    username: string;
    signInUserSession: {
      idToken: {
        payload: any;
      };
      getIdToken: () => {
        getJwtToken: () => string;
      };
    };
  };
  signOut: () => void;
}

export interface AppContextType {
  user: { username: string; signInUserSession?: any; attributes?: any };
  appLoading: boolean;
  setAppLoading: (value: boolean) => void;
  activeProject: ProjectObject;
  setActiveProject: (value: ProjectObject | {}) => void;
  refreshProjectObject: () => Promise<void>;
}

export const appContext: Context<AppContextType> =
  createContext<AppContextType>({
    user: {} as { username: string; signInUserSession?: any; attributes?: any },
    appLoading: false,
    setAppLoading: (value: boolean): void => {},
    // The project is selected via URL
    activeProject: {},
    setActiveProject: (value: ProjectObject): void => {},
    refreshProjectObject: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
  });

const App: React.FC<AppProps> = ({ user, signOut }): JSX.Element => {
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [activeProject, setActiveProject] = useState<ProjectObject>({});

  // High level state to control the Side Drawer via sibbling Navbar
  const [openedMobileDrawer, setOpenedMobileDrawer] = useState(false);

  /**
   * Get project object and update context
   */
  const refreshProjectObject = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (activeProject?.id) {
        const company: string =
          user.signInUserSession.idToken.payload['custom:company'];
        getProject(company, activeProject.id, true)
          .then((value: ProjectObject) => {
            setActiveProject(value);
            resolve();
          })
          .catch((err) => {
            errorHandler(err);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  };

  return (
    <BrowserRouter>
      <appContext.Provider
        value={{
          user,
          appLoading,
          setAppLoading,
          activeProject,
          setActiveProject,
          refreshProjectObject,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <React.Suspense fallback={<AppScopeLoadingComponent />}>
            <AppRouter
              user={user}
              setOpenedMobileDrawer={setOpenedMobileDrawer}
              openedMobileDrawer={openedMobileDrawer}
              signOut={signOut}
            />
          </React.Suspense>
          <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
        </Box>
      </appContext.Provider>
    </BrowserRouter>
  );
};

export default App;
