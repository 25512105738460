import { AuthenticatedApiRequest } from 'gsf-util-react-auth';
import { ProjectObject } from '../../TypeScript/interfaces';

export const getProject = (
  company: string,
  projectId: string,
  expand?: boolean
): Promise<ProjectObject> => {
  return new Promise((resolve, reject) => {
    let path: string = '';
    if (expand) {
      path = `/${company}/${projectId}?expand=true`;
    } else {
      path = `/${company}/${projectId}`;
    }
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        const projectObject: ProjectObject = result;
        resolve(projectObject);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
