import { ThemeProvider } from '@mui/material/styles';
import { gsTheme } from './themes/gs-theme';
import React from 'react';
// GENERAL STYLE
import './themes/gs-style.scss'; // last to override other styling

const StylerHOC = <T extends {}>(
  WrappedComponent: React.FC<T>
): React.FC<T> => {
  return (props: T): JSX.Element => {
    return (
      <ThemeProvider theme={gsTheme}>
        <WrappedComponent {...(props as any)} />
      </ThemeProvider>
    );
  };
};

export default StylerHOC; // Naming it Styler because it add Theme and our special style/css
